import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import 'rxjs-compat/add/operator/catch';
import {Router} from '@angular/router';
import {catchError} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {HelperService} from '../../helper.service';
var moment = require('moment-timezone');

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private httpOptions: any;
  userType:any =  localStorage.getItem('user_type');

  constructor(private http: HttpClient, private router: Router, private CSHelper: HelperService) {
  }

  baseUrl() {
    return localStorage.getItem('user_type') === '2' ? environment.assistantBaseURL : environment.apiBaseUrl;
  }

  getBasicHeader() {
    this.httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        language : localStorage.getItem('language') === 'en' ? '1' : '2'
      }),
    };
    return  this.httpOptions;
  }

  getAuthHeader() {
    const token = JSON.parse(localStorage.getItem('loginData'));
    const timezone:any =  Intl.DateTimeFormat().resolvedOptions().timeZone
    if (token) {
      this.httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: 'Bearer ' + token.token,
          timezone : timezone.toString() === 'Asia/Calcutta' ? 'Asia/Kolkata' : timezone.toString(),
          language: localStorage.getItem('language') === 'en' ? '1' : '2'
        }),
      };
    }else{
      this.httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          timezone : timezone.toString() === 'Asia/Calcutta' ? 'Asia/Kolkata' : timezone.toString(),
          language: localStorage.getItem('language')  === 'en' ? '1' : '2'
        }),
      }
    }
    return this.httpOptions;
  }

  requestResponseHeader(): any {
    const token = JSON.parse(localStorage.getItem('loginData'));
    const language:string =  localStorage.getItem('language') === 'en' ? '1' : '2'
    const timezone:any =  Intl.DateTimeFormat().resolvedOptions().timeZone
    if (token) {
      this.httpOptions = {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + token.token,
          timezone : timezone.toString() === 'Asia/Calcutta' ? 'Asia/Kolkata' : timezone.toString(),
          language: language.toString(),
        }),
        responseType: 'blob'
      };
    }
    return this.httpOptions;
  }


  public isAuthenticated(): boolean {
    const isAuth = JSON.parse(localStorage.getItem('loginData'));
    return !!isAuth;
  }

  //  Authentication Features
  login(data): Observable<any> {

    return this.http.post(this.baseUrl()
      + 'login', data, this.getBasicHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  signUp(data): Observable<any> {
    return this.http.post(this.baseUrl()
      + 'sign-up', data, this.getBasicHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  industryList(): Observable<any> {
    return this.http.get(environment.apiBaseUrl
      + 'industry-list', this.getBasicHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  countryList(): Observable<any> {
    return this.http.get(environment.apiBaseUrl
      + 'country-list', this.getBasicHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  forgotPassword(data): Observable<any> {
    return this.http.post(this.baseUrl()
      + 'forgot-password', data, this.getBasicHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  verifyForgotPassword(data): Observable<any> {
    return this.http.post(this.baseUrl()
      + 'verify-forgot-password', data, this.getBasicHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  resetPassword(data): Observable<any> {
    return this.http.post(this.baseUrl()
      + 'reset-password', data, this.getBasicHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  // CMS Module
  getCMSDetails(type): Observable<any> {
    return this.http.get(`${environment.apiBaseUrl
    }cms/${type}`, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  // add bank details Module
  getBankList(): Observable<any> {
    return this.http.get(this.baseUrl()
      + 'bank-list', this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  addEditBankDetails(data): Observable<any> {
    return this.http.post(this.baseUrl()
      + 'bank-add-edit', data, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  // verify Email
  verifyEmail(data, link): Observable<any> {
    return this.http.post(this.baseUrl()
      + link, data, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  // city list
  getCityList(): Observable<any> {
    return this.http.get(this.baseUrl()
      + 'city-list').pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  // Venue List
  getVenueList(requestParameters): Observable<any> {
    return this.http.get(this.baseUrl()
      + 'venue-list' + requestParameters).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  // Add Venue
  addVenue(data): Observable<any> {
    return this.http.post(this.baseUrl()
      + 'venue-add', data, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  // Delete User Venue
  deleteUserVenue(venueId): Observable<any> {
    return this.http.get(this.baseUrl()
      + 'venue-delete/' + venueId, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  // User Award List
  getUserAwardList(): Observable<any> {
    return this.http.get(this.baseUrl()
      + 'user-award-list', this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  // add edit User Award
  addEditUserAward(requestParam, data): Observable<any> {
    return this.http.post(this.baseUrl()
      + 'awards-add-edit' + requestParam, data, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  // delete User Award
  deleteUserAward(awardId): Observable<any> {
    return this.http.get(this.baseUrl()
      + 'awards-delete/' + awardId, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  // add Basic Details
  addBasicDetails(data): Observable<any> {
    return this.http.post(this.baseUrl()
      + 'basic-profile-add', data, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  // User Venue List
  userVenueList(): Observable<any> {
    return this.http.get(this.baseUrl()
      + 'user-venue-list', this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  physicalAppointmentsTiming(data): Observable<any> {
    return this.http.post(this.baseUrl()
      + 'physical-appointments-timing', data, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }
  deleteTimings(data): Observable<any> {
    return this.http.post(this.baseUrl()
      + 'delete-timing', data, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  telemedicineAppointmentsTiming(data): Observable<any> {
    return this.http.post(this.baseUrl()
      + 'telemedicine-appointments-timing', data, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  getProfileDetail(): Observable<any> {
    return this.http.get(this.baseUrl()
      + 'profile-detail', this.getAuthHeader()).pipe(
      catchError((err => {
        // this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  subscriptionPlanDetail(): Observable<any> {
    return this.http.get(this.baseUrl()
      + 'subscription-plan-detail', this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  // Doctor List
  getDoctorList(search): Observable<any> {
    return this.http.get(this.baseUrl()
      + 'doctor-list' + search, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  // Invite doctor
  inviteDoctor(data): Observable<any> {
    return this.http.post(this.baseUrl()
      + 'invite-doctor', data, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  // invite doctor List
  getInviteDoctor(): Observable<any> {
    return this.http.get(this.baseUrl()
      + 'invite-doctor-list', this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  // Delete Invite Doctor
  deleteInviteDoctor(id): Observable<any> {
    return this.http.get(this.baseUrl()
      + 'invite-doctor-delete/' + id, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  insuranceList(): Observable<any> {
    return this.http.get(this.baseUrl()
      + 'insurance-list', this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  bookingConfig(data): Observable<any> {
    return this.http.post(this.baseUrl()
      + 'booking-config', data, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  // service provider list
  getServiceProviderList(query): Observable<any> {
    return this.http.get(this.baseUrl()
      + 'service-provider-list' + query, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  // Delete Service Provider
  deleteServiceProvider(id): Observable<any> {
    return this.http.get(this.baseUrl()
      + 'service-provider-delete/' + id, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  // Add/Edit Service provider
  addEditServiceProvider(requestParam, data): Observable<any> {
    return this.http.post(this.baseUrl()
      + 'service-provider-add-edit' + requestParam, data, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  // service list
  getServiceList(search): Observable<any> {
    return this.http.get(this.baseUrl()
      + 'service-list' + search, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  // user chat list
  userChatList(search, type, timestamp?): Observable<any> {
    const timestampRequest = timestamp && timestamp && timestamp > 0 ? timestamp : ''
    const objBody = {
      timestamp: timestampRequest,
      search: search,
    }
    if(localStorage.getItem('user_type') === '2' && type == 2 ){
      return this.http.post(this.baseUrl()
        + 'group-chat-list', objBody, this.getAuthHeader()).pipe(
        catchError((err => {
            this.CSHelper.handleAuthError(err);
            return throwError(err)
          })
        ));
    }else{
      return this.http.post(this.baseUrl()
        + 'user-chat-list/' + type, objBody, this.getAuthHeader()).pipe(
        catchError((err => {
            this.CSHelper.handleAuthError(err);
            return throwError(err)
          })
        ));
    }

  }

  userChatDetails(id, timestamp?): Observable<any> {
    const timestampRequest = timestamp && timestamp && timestamp > 0 ? '?timestamp=' + timestamp : ''
    return this.http.get(this.baseUrl()
      + 'user-chat-details/' + id + timestampRequest, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  getGroupChatDetail(groupId, timestamp?): Observable<any> {
    const timestampRequest = timestamp && timestamp && timestamp > 0 ? '?timestamp=' + timestamp : ''
    return this.http.get(this.baseUrl()
      + 'group-chat-details/' + groupId + timestampRequest, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  mediaUpload(data) {
    return this.http.post(this.baseUrl()
      + 'media-upload', data, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  // Edit Profile
  editProfile(data): Observable<any> {
    return this.http.post(this.baseUrl()
      + 'edit-profile', data, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  // Contact US
  getContactDetails(): Observable<any> {
    return this.http.get(environment.apiBaseUrl
      + 'contact-us-list').pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  // faq screen
  getFaq(): Observable<any> {
    return this.http.get(environment.apiBaseUrl
      + 'faqs').pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  // change password
  changePassword(data): Observable<any> {
    return this.http.post(this.baseUrl()
      + 'change-password', data, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  // Add Edit Assistant
  addEditAssistant(data, id): Observable<any> {
    return this.http.post(this.baseUrl()
      + 'assistant-add-edit' + id, data, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  // Get Assistant List
  getAssistantList(): Observable<any> {
    return this.http.get(this.baseUrl()
      + 'assistant-list', this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  // Delete Assistant
  deleteAssistant(id): Observable<any> {
    return this.http.get(this.baseUrl()
      + 'assistant-delete/' + id, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  homeDetail(query): Observable<any> {
    return this.http.get(this.baseUrl()
      + 'home' + query, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  startAppointment(id): Observable<any> {
    return this.http.post(this.baseUrl()
      + 'start-appointment/' + id, '', this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  homeFilter(data): Observable<any> {
    return this.http.post(this.baseUrl()
      + 'filter', data, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  // get bank details
  getBankDetails(): Observable<any> {
    return this.http.get(this.baseUrl()
      + 'bank-detail', this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  appointmentDetail(id): Observable<any> {
    return this.http.get(this.baseUrl()
      + 'appointment-detail/' + id, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  availableBookingSlot(data): Observable<any> {
    return this.http.post(this.baseUrl()
      + 'available-booking-slot', data, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  serviceProviderWithFilter(data): Observable<any> {
    return this.http.post(this.baseUrl()
      + 'service-provider-with-filter', data, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  editAppointment(data): Observable<any> {
    return this.http.post(this.baseUrl()
      + 'edit-appointment', data, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  cancelAppointment(id): Observable<any> {
    return this.http.get(this.baseUrl()
      + 'cancel-appointment/' + id, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  skipStep(data): Observable<any> {
    return this.http.post(this.baseUrl()
      + 'skip-step', data, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  userStatusList(): Observable<any> {
    return this.http.get(this.baseUrl()
      + 'user-status-list', this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  setUserStatus(data): Observable<any> {
    return this.http.post(this.baseUrl()
      + 'set-user-status', data, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  turnDetails(data): Observable<any> {
    return this.http.post(this.baseUrl()
      + 'turn-details', data, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  resetStatus(id): Observable<any> {
    return this.http.get(this.baseUrl()
      + 'reset-status/' + id, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  addPrescription(data): Observable<any> {
    return this.http.post(this.baseUrl() + 'add-prescription', data, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  customerAppointmentList(id, type): Observable<any> {
    return this.http.get(this.baseUrl() + 'customer-appointment-list/' + id + type, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  addEditDiscount(data): Observable<any> {
    return this.http.post(this.baseUrl() + 'add-edit-discount', data, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  telemedicineBookingSlot(data): Observable<any> {
    return this.http.post(this.baseUrl() + 'telemedicine-booking-slot', data, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  getPartnerDetails(): Observable<any> {
    return this.http.get(this.baseUrl() + 'partner-detail', this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  reportList(data): Observable<any> {
    return this.http.post(this.baseUrl()
      + 'report-list', data, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  reportSetting(query): Observable<any> {
    return this.http.post(this.baseUrl()
      + 'report-setting', query, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  reportDetail(id): Observable<any> {
    return this.http.get(this.baseUrl()
      + 'report-detail/' + id, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  revertReport(data): Observable<any> {
    return this.http.post(this.baseUrl() + 'revert-report', data, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  completeAppointment(id, data): Observable<any> {
    return this.http.post(this.baseUrl()
      + 'complete-appointment/' + id, data, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  checkoutDetail(id): Observable<any> {
    return this.http.get(this.baseUrl()
      + 'checkout-details/' + id, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  finalCheckout(id, data): Observable<any> {
    return this.http.post(this.baseUrl()
      + 'final-checkout/' + id, data, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  emergencyRequest(): Observable<any> {
    return this.http.get(this.baseUrl()
      + 'emergency-request', this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  // add patient
  addPatient(data): Observable<any> {
    return this.http.post(this.baseUrl()
      + 'add-patient', data, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }
  // patientList
  getPatientList(search): Observable<any> {
    return this.http.get(this.baseUrl()
      + 'patient-list' + search, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }
  getPatientDetails(data): Observable<any> {
    return this.http.get(this.baseUrl()
      + 'patient-list/' + data, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }
  // landing contact us
  addContactUs(data): Observable<any> {
    return this.http.post(environment.apiBaseUrl + 'contact-us', data).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }
  saveQuickblox(data): Observable<any> {
    return this.http.post(this.baseUrl()
      + 'save-quickblox', data, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }
  updateDeviceToken(data): Observable<any> {
    if(JSON.parse(localStorage.getItem('loginData'))){
      return this.http.post(this.baseUrl()
        + 'device-token', data, this.getAuthHeader()).pipe(
        catchError((err => {
            // this.CSHelper.handleAuthError(err);
            return throwError(err)
          })
        ));
    }
  }

  // Assistant App API
  getVenueListForBooking(data): Observable<any> {
    return this.http.post(environment.assistantBaseURL + 'partner-venue-list', data , this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }
  getServiceListForBooking(data): Observable<any> {
    return this.http.post(environment.assistantBaseURL + 'partner-service-list', data , this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }
  getServiceProviderListForBooking(data): Observable<any> {
    return this.http.post(environment.assistantBaseURL + 'partner-provider-list', data , this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  getAvailableBookingDate(data): Observable<any> {
    return this.http.post(this.baseUrl()
      + 'available-booking-date', data, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }
  confirmBooking(data): Observable<any> {
    return this.http.post(this.baseUrl()
      + 'confirm-booking', data, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }
  makeBooking(data): Observable<any> {
    return this.http.post(environment.assistantBaseURL + 'book-appointment', data , this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }
  createChatGroup(data): Observable<any> {
    return this.http.post(environment.assistantBaseURL + 'create-chat-group', data , this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }
  notificationList(timestamp?): Observable<any> {
    const timestampString = timestamp && timestamp && timestamp > 1 ? '?timestamp=' + timestamp : ''
    return this.http.get(this.baseUrl()
      + 'notification-list' + timestampString, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }
  unreadCount(): Observable<any> {
    return this.http.get(this.baseUrl()
      + 'unread-count', this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }
  markAsRead(data): Observable<any> {
    return this.http.post(this.baseUrl()
      + 'mark-as-read', data ,this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }
  // Revenue Flow
  getRevenueList(data): Observable<any> {
    return this.http.post(this.baseUrl() + `view-revenue` , data, this.getAuthHeader()).pipe(
      catchError((err => { this.CSHelper.handleAuthError(err); return throwError(err) })));
  }
  getRevenueDetails(queryString): Observable<any> {
    return this.http.get(this.baseUrl() + `revenue-details${queryString}`, this.getAuthHeader()).pipe(
      catchError((err => { this.CSHelper.handleAuthError(err); return throwError(err) })));
  }

  exportRevenueList(data): Observable<any> {
    return this.http.post(this.baseUrl() + `export-revenue`  , data, this.requestResponseHeader()).pipe(
      catchError((err) => {this.CSHelper.handleAuthError(err); return throwError(err); }));
  }
  exportRevenueDetails(queryString): Observable<any> {
    return this.http.get(this.baseUrl() + `export-revenue-details${queryString}`, this.requestResponseHeader()).pipe(
      catchError((err) => {this.CSHelper.handleAuthError(err); return throwError(err); }));
  }
  getPhysicalStatus(): Observable<any> {
    return this.http.get(this.baseUrl() + 'physical-status', this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }
  getUserStatus(): Observable<any> {
    return this.http.get(this.baseUrl() + 'get-user-status', this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }
  createNewCustomer(data): Observable<any> {
    return this.http.post(this.baseUrl() + 'create-new-customer', data).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }
  partnerCardDetails(data): Observable<any> {
    return this.http.post(this.baseUrl() + 'partner-card-details', data).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }
  deleteCard(data): Observable<any> {
    return this.http.post(this.baseUrl() + 'delete-card', data).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }
  markAsDefaultCard(data): Observable<any> {
    return this.http.post(this.baseUrl() + 'mark-as-default-card', data).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }
  subscriptionActivation(data): Observable<any> {
    return this.http.post(this.baseUrl() + 'subscription-activation', data).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }
  changeSubscriptionPlan(data): Observable<any> {
    return this.http.post(this.baseUrl() + 'change-subscription-plan', data).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }
  submitActivationCard(data): Observable<any> {
    return this.http.post(this.baseUrl() + 'submit-activation-card', data).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }
  getInvoiceList(query): Observable<any> {
    return this.http.get(this.baseUrl()
      + 'invoice-list' + query, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }

  addEditInvoice(data, id): Observable<any> {
    const editInvoice = id ? '/' + id : '';
    return this.http.post(this.baseUrl()
      + 'invoice-add-edit' + editInvoice , data, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }
  uploadInvoiceDocument(data): Observable<any> {
    return this.http.post(this.baseUrl()
      + 'upload-invoice-document', data, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }
  invoiceDetail(id): Observable<any> {
    return this.http.get(this.baseUrl()
      + 'invoice-detail/' + id, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }
  deleteInvoice(venueId): Observable<any> {
    return this.http.get(this.baseUrl()
      + 'invoice-delete/' + venueId, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }
  listRatingReview(data): Observable<any> {
    return this.http.get(this.baseUrl() + `list-rating-review` +  data, this.getAuthHeader()).pipe(
      catchError((err => { this.CSHelper.handleAuthError(err); return throwError(err) })));
  }

  addEditRecord(data): Observable<any> {
    return this.http.post(this.baseUrl()
      + 'add-edit-record', data, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }
  getRecordList(data): Observable<any> {
    return this.http.post(this.baseUrl()
      + 'record-list',data , this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }
  medicationList(data): Observable<any> {
    return this.http.post(this.baseUrl()
      + 'medication-list',data , this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }
  recordDetails(id): Observable<any> {
    return this.http.get(this.baseUrl()
      + 'record-details/' + id, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }
  deleteRecord(Id): Observable<any> {
    return this.http.get(this.baseUrl()
      + 'delete-record/' + Id, this.getAuthHeader()).pipe(
      catchError((err => {
          this.CSHelper.handleAuthError(err);
          return throwError(err)
        })
      ));
  }
  getAllowReportStatus(): Observable<any> {
    return this.http.get(this.baseUrl() + `get-allow-report-status`, this.getAuthHeader()).pipe(
      catchError((err => { this.CSHelper.handleAuthError(err); return throwError(err) })));
  }
  partnerCityList(): Observable<any> {
    return this.http.get(this.baseUrl() + `partner-city-list`, this.getAuthHeader()).pipe(
      catchError((err => { this.CSHelper.handleAuthError(err); return throwError(err) })));
  }
}
