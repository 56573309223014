import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ServiceList} from '../../../utils/dataSets';
import {HelperService} from '../../../helper.service';
import {ApiService} from '../../services/api.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {fromEvent} from 'rxjs';
import {debounceTime, map} from 'rxjs/operators';

@Component({
  selector: 'app-service-list-modal',
  templateUrl: './service-list-modal.component.html',
  styleUrls: ['./service-list-modal.component.scss']
})
export class ServiceListModalComponent implements OnInit {
  @Output() clickEvent = new EventEmitter<any>();
  @Input() serviceList: ServiceList[];
  @Input() venueId;
  @Input() serviceId;
  selectedService;
  searchString = ''
  @ViewChild('searchRef', {static: true}) searchRef: ElementRef;


  constructor(public helpers: HelperService,
              private apiService: ApiService,
              public activeModal: NgbActiveModal,) { }

  ngOnInit(): void {
    const obs = fromEvent(this.searchRef.nativeElement, 'keyup')
      .pipe(
        map((e: any) => e.target.value),
        debounceTime(500),
        map((query: string) => this.applySearch(query)));

    obs.subscribe((result) => {
    });
    if(this.selectedService){
      this.getSelectedService();
    }
  }
  getSelectedService(){
    this.selectedService = this.serviceList.filter(x => x.service_id === this.serviceId)[0];
  }

  applySearch(query): void {
    this.searchString =  query.trim();
    this.getServiceList();
  }
  getServiceList(){
    const formData = new FormData();
    if(this.searchString && this.searchString.trim() !== ''){
      formData.append('search' , this.searchString);
    }
    if(this.venueId) {
      formData.append('venue_id' , this.venueId);
    }
    this.helpers.showSpinner();
    this.apiService.getServiceListForBooking(formData).subscribe((data: any) => {
      this.helpers.hideSpinner();
      if (data.meta.code === 1) {
        this.serviceList = data.data;
      } else {
        this.helpers.showErrorMessage(data.meta.message);
      }
    }, err => {
      this.helpers.hideSpinner();
    });

  }

  changeService(event , venue): void{
    this.selectedService = venue;
  }

  selectVenue(event): void{
    this.clickEvent.emit({
      selectedService: this.selectedService
    })
  }

  clearSearch(searchRef): void {
    searchRef.value = ''
    this.searchString = ''
    this.getServiceList();
  }

}
