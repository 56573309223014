import {Component, OnInit, Renderer2} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MessagingService} from './services/messaging.service';
import {HelperService} from './helper.service';
// import {environment} from "../environments/environment";

declare var QB: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  message;
  loginUser = JSON.parse(localStorage.getItem('loginData'));
    constructor(public translate: TranslateService,
                public msgService: MessagingService,
                public helpers: HelperService,
                private renderer: Renderer2) {
        translate.addLangs(['en', 'es']);
        translate.setDefaultLang('es');
      const browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|es/) ? browserLang : 'es');
    }
  title = 'waitless';
  ngOnInit() {
    if(this.loginUser) {
      this.msgService.requestPermission(0)
      this.msgService.receiveMessage()
      this.message = this.msgService.currentMessage
    }
    // this.addJsToElement();
  }
  /*addJsToElement(): HTMLScriptElement {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src =  environment.cardnetURL + environment.cardnetKEY;
    this.renderer.appendChild(document.body, script);
    return script;
  }*/

}
