import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {ReactiveFormsModule,FormsModule} from "@angular/forms";
import {FooterComponent} from "./footer/footer.component";
import {HeaderComponent} from "./header/header.component";
import {TranslateModule} from "@ngx-translate/core";
import { VenueListModalComponent } from './modal/venue-list-modal/venue-list-modal.component';
import {Ng2SearchPipeModule} from 'ng2-search-filter';
import { ServiceListModalComponent } from './modal/service-list-modal/service-list-modal.component';
import { ServiceProviderListModalComponent } from './modal/service-provider-list-modal/service-provider-list-modal.component';
import {NgSelectModule} from '@ng-select/ng-select';
import { SelectPaymentMethodComponent } from './modal/select-payment-method/select-payment-method.component';

@NgModule({
    exports: [
        CommonModule,
        NgbModule,
        FooterComponent,
        HeaderComponent,
    ],
    imports: [
        RouterModule,
        CommonModule,
        NgbModule,
        ReactiveFormsModule,
        FormsModule,
        TranslateModule,
        Ng2SearchPipeModule,
        NgSelectModule
    ],
    declarations: [ FooterComponent, HeaderComponent, VenueListModalComponent, ServiceListModalComponent, ServiceProviderListModalComponent, SelectPaymentMethodComponent ]
})
export class SharedModule { }
