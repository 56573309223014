import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormGroup} from '@angular/forms';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-select-payment-method',
  templateUrl: './select-payment-method.component.html',
  styleUrls: ['./select-payment-method.component.scss']
})
export class SelectPaymentMethodComponent implements OnInit {
  paymentMethod:any = 2;
  @Output() clickEvent = new EventEmitter<any>();
  paymentMethods = {
    2 : this.translationService.instant('CHECKOUT.credit_debit_card_swipe'),
    3 :  this.translationService.instant('CHECKOUT.cash')
  }

  constructor(public activeModal: NgbActiveModal,private translationService: TranslateService,) { }

  ngOnInit(): void {
  }

  changePaymentMethod(type){
    this.paymentMethod =  type;
  }

  confirmMethod(type){
    this.clickEvent.emit({
      selectedMethod: {
        id: type,
        name: this.paymentMethods[type]
      }
    })

  }

}
