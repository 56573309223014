import {Routes, RouterModule} from '@angular/router';

// Route for content layout without sidebar, navbar and footer for pages like Login, Registration etc...

export const CONTENT_ROUTES: Routes = [
     {
        path: '',
        loadChildren: () => import('../../pages/auth/auth.module').then(m => m.AuthModule)
    },
    {
        path: 'home',
        loadChildren: () => import('../../pages/home/home.module').then(m => m.HomeModule)
    },
    {
        path: 'privacy-policy',
        loadChildren: () => import('../../pages/cms/cms.module').then(m => m.CmsModule),
        data: {
          type: 'privacy-policy',
          title: 'Privacy Policy'
      }
    },
   {
        path: 'terms-conditions-payment',
        loadChildren: () => import('../../pages/cms/cms.module').then(m => m.CmsModule),
        data: {
          type: 'terms-conditions-payment',
          title: 'Terms-conditions-payment'
       }
    },
  {
    path: 'terms-conditions',
    loadChildren: () => import('../../pages/cms/cms.module').then(m => m.CmsModule),
    data: {
      type: 'terms-conditions',
      title: 'Terms and Condition'
    }
  },
    {
        path: 'add-bank-details',
        loadChildren: () => import('../../pages/add-bank-details/add-bank-details.module').then(m => m.AddBankDetailsModule)
    },
    {
        path: 'set-profile',
        loadChildren: () => import('../../pages/set-profile/set-profile.module').then(m => m.SetProfileModule)
    },
    {
        path: 'appointment',
        loadChildren: () => import('../../pages/appointment/appointment.module').then(m => m.AppointmentModule)
    },
    {
        path: 'add-service-provider',
        loadChildren: () => import('../../pages/add-service-provider/add-service-provider.module').then(m => m.AddServiceProviderModule)
    },
    {
      path: 'refer-doctors',
      loadChildren: () => import('../../pages/refer-doctors/refer-doctors.module').then(m => m.ReferDoctorsModule)
    },
    {
        path: 'no-service-provider',
        loadChildren: () => import('../../pages/no-service-provider/no-service-provider.module').then(m => m.NoServiceProviderModule)
    },
    {
      path: 'service-provider-list',
      loadChildren: () => import('../../pages/service-provider-list/service-provider-list.module').then(m => m.ServiceProviderListModule)
    },
    {
      path: 'notification',
      loadChildren: () => import('../../pages/notification/notification.module').then(m => m.NotificationModule)
    },
    {
      path: 'booking',
      loadChildren: () => import('../../pages/booking/booking.module').then(m => m.BookingModule)
    },
    {
      path: 'myprofile',
      loadChildren: () => import('../../pages/myprofile/myprofile.module').then(m => m.MyprofileModule)
    },
    {
      path: 'chat',
      loadChildren: () => import('../../pages/chat/chat.module').then(m => m.ChatModule)
    },
    {
      path: 'select-timing',
      loadChildren: () => import('../../pages/select-timing/select-timing.module').then(m => m.SelectTimingModule)
    },
    {
      path: 'edit-profile',
      loadChildren: () => import('../../pages/edit-profile/edit-profile.module').then(m => m.EditProfileModule)
    },
    {
      path: 'settings',
      loadChildren: () => import('../../pages/settings/settings.module').then(m => m.SettingsModule)
    },
    {
      path: 'contactus',
      loadChildren: () => import('../../pages/contactus/contactus.module').then(m => m.ContactusModule)
    },
    {
      path: 'manage-assistant',
      loadChildren: () => import('../../pages/manage-assistant/manage-assistant.module').then(m => m.ManageAssistantModule)
    },
    {
      path: 'faq',
      loadChildren: () => import('../../pages/faq/faq.module').then(m => m.FaqModule)
    },
    {
      path: 'agenda',
      loadChildren: () => import('../../pages/agenda/agenda.module').then(m => m.AgendaModule)
    },
    {
      path: 'edit-turn',
      loadChildren: () => import('../../pages/edit-turn/edit-turn.module').then(m => m.EditTurnModule)
    },
    {
      path: 'update-status',
      loadChildren: () => import('../../pages/update-status/update-status.module').then(m => m.UpdateStatusModule)
    },
    {
      path: 'edit-appointment/:id',
      loadChildren: () => import('../../pages/edit-appointment/edit-appointment.module').then(m => m.EditAppointmentModule)
    },
    {
      path: 'booking-information/:id',
      loadChildren: () => import('../../pages/booking-information/booking-information.module').then(m => m.BookingInformationModule)
    },
    {
      path: 'reports',
      loadChildren: () => import('../../pages/reports/reports.module').then(m => m.ReportsModule)
    },
    {
      path: 'checkout/:id',
      loadChildren: () => import('../../pages/checkout/checkout.module').then(m => m.CheckoutModule)
    },
    {
      path: 'revert/:id',
      loadChildren: () => import('../../pages/revert/revert.module').then(m => m.RevertModule)
    },
    {
      path: 'payment-method/:id',
      loadChildren: () => import('../../pages/payment-method/payment-method.module').then(m => m.PaymentMethodModule)
    },
    {
      path: 'revenue-details/:date',
      loadChildren: () => import('../../pages/revenue-details/revenue-details.module').then(m => m.RevenueDetailsModule)
    },
    {
      path: 'view-revenue',
      loadChildren: () => import('../../pages/view-revenue/view-revenue.module').then(m => m.ViewRevenueModule)
    },
    {
      path: 'add-patient',
      loadChildren: () => import('../../pages/add-patient/add-patient.module').then(m => m.AddPatientModule)
    },
    {
      path: 'schedule-service/:id',
      loadChildren: () => import('../../pages/schedule-service/schedule-service.module').then(m => m.ScheduleServiceModule)
    },
    {
      path: 'select-service',
      loadChildren: () => import('../../pages/select-service/select-service.module').then(m => m.SelectServiceModule)
    },
    {
      path: 'manage-invoice',
      loadChildren: () => import('../../pages/manage-invoice/manage-invoice.module').then(m => m.ManageInvoiceModule)
    },
    {
      path: 'add-invoice',
      loadChildren: () => import('../../pages/add-edit-invoice/add-edit-invoice.module').then(m => m.AddEditInvoiceModule)
    },
    {
      path: 'add-invoice/:id',
      loadChildren: () => import('../../pages/add-edit-invoice/add-edit-invoice.module').then(m => m.AddEditInvoiceModule)
    },
    {
      path: 'no-invoice',
      loadChildren: () => import('../../pages/no-invoice/no-invoice.module').then(m => m.NoInvoiceModule)
    },
    {
      path: 'invoice/:id',
      loadChildren: () => import('../../pages/invoice-detail/invoice-detail.module').then(m => m.InvoiceDetailModule)
    },
    {
      path: 'rating-reviews',
      loadChildren: () => import('../../pages/rating-reviews/rating-reviews.module').then(m => m.RatingReviewsModule)
    },
    {
      path: 'booking-summary/:id/:bookingType/:customerId',
      loadChildren: () => import('../../pages/booking-summary/booking-summary.module').then(m => m.BookingSummaryModule)
    },
    {
      path: 'allergies/:id/:cId',
      loadChildren: () => import('../../pages/allergies/allergies.module').then(m => m.AllergiesModule)
    },
    {
      path: 'allergies-detail/:id/:cId',
      loadChildren: () => import('../../pages/allergies-detail/allergies-detail.module').then(m => m.AllergiesDetailModule)
    },
    {
      path: 'add-prescription/:id/:cId',
      loadChildren: () => import('../../pages/add-prescription/add-prescription.module').then(m => m.AddPrescriptionModule)
    },
    {
      path: 'edit-prescription/:id/:pId/:cId',
      loadChildren: () => import('../../pages/add-prescription/add-prescription.module').then(m => m.AddPrescriptionModule)
    },
    {
      path: 'prescriptions/:id/:cId',
      loadChildren: () => import('../../pages/prescriptions/prescriptions.module').then(m => m.PrescriptionsModule)
    },
    {
      path: 'prescriptions-detail/:id/:cId',
      loadChildren: () => import('../../pages/prescriptions-detail/prescriptions-detail.module').then(m => m.PrescriptionsDetailModule)
    },
    {
      path: 'add-allergy/:id/:cId',
      loadChildren: () => import('../../pages/add-allergy/add-allergy.module').then(m => m.AddAllergyModule)
    },
    {
      path: 'edit-allergy/:id/:aId/:cId',
      loadChildren: () => import('../../pages/add-allergy/add-allergy.module').then(m => m.AddAllergyModule)
    },
    {
      path: 'illnesses/:id/:cId',
      loadChildren: () => import('../../pages/illnesses/illnesses.module').then(m => m.IllnessesModule)
    },
    {
      path: 'illnesses-detail/:id/:cId',
      loadChildren: () => import('../../pages/illnesses-detail/illnesses-detail.module').then(m => m.IllnessesDetailModule)
    },
    {
      path: 'add-illnesses/:id/:cId',
      loadChildren: () => import('../../pages/add-illnesses/add-illnesses.module').then(m => m.AddIllnessesModule)
    },
    {
        path: 'edit-illnesses/:id/:iId/:cId',
        loadChildren: () => import('../../pages/add-illnesses/add-illnesses.module').then(m => m.AddIllnessesModule)
     },
    {
      path: 'confirmation',
      loadChildren: () => import('../../pages/confirmation/confirmation.module').then(m => m.ConfirmationModule)
    },
    {
      path: 'activation-card/:id',
      loadChildren: () => import('../../pages/activation-card/activation-card.module').then(m => m.ActivationCardModule)
    },
    {
      path: 'add-bank/:id/:ID1',
      loadChildren: () => import('../../pages/add-bank/add-bank.module').then(m => m.AddBankModule)
    },
    {
      path: 'revert-detail/:id',
      loadChildren: () => import('../../pages/revert-detail/revert-detail.module').then(m => m.RevertDetailModule)
    },
    {
      path: 'android-partner',
      loadChildren: () => new Promise( () => { if(window.location.href.match(/android-partner/) ) window.location.href = 'https://play.google.com/store/apps/details?id=com.waitless.partner'; } )
    },
    {
      path: 'ios-partner',
      loadChildren: () => new Promise( () => { if(window.location.href.match(/ios-partner/) ) window.location.href = 'https://apps.apple.com/us/app/id1516416425'; } )
    },
    {
      path: 'android-customer',
      loadChildren: () => new Promise( () => { if(window.location.href.match(/android-customer/) ) window.location.href = 'https://play.google.com/store/apps/details?id=com.waitless.user'; } )
    },
    {
      path: 'ios-customer',
      loadChildren: () => new Promise( () => { if(window.location.href.match(/ios-customer/) ) window.location.href = 'https://apps.apple.com/us/app/id1516416415'; } )
    },
    {
      path: 'error',
      loadChildren: () => import('../../pages/error/error.module').then(m => m.ErrorModule)
    }

];
