import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import {ApiService} from "../services/api.service";

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService,public auth: ApiService,private router:Router) {}

   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
     return this.authService.isAuthenticated();
   }
   
}
