import {ElementRef, Injectable, ViewChild} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {HttpErrorResponse} from '@angular/common/http';
import {AbstractControl} from '@angular/forms';
import * as CryptoJS from 'crypto-js';
import {BehaviorSubject} from 'rxjs';
import * as moment from "moment";
import Swal from "sweetalert2";
import {TranslateService} from "@ngx-translate/core";
@Injectable({
  providedIn: 'root'
})
export class HelperService {
  userData:any = [];
  userChatDetail:any = [];
  dispalyVideoCall =  false;
  public calling: boolean = false;
  timeLeft: number = 0;
  perPage = 10;
  token: any;
  quickbloxId:any;
  loginHeader = true;
  loginButton = false;
  loginButtonCommon = false;
  profileStep: any;
  singleLoginButton = false;
  singleSignUpButton = false;
  basicMenu = false;
  noAccountYet = false;
  alreadyHave = false;
  profileIcon = {
    myProfile: false,
    logout: false,
  };
  displaySetting: boolean;
  mainMenu = false;
  customerId:any = '';
  bookingId:any = '';
  startCall:boolean =  false;
  otherTabredirect: boolean =  false;
  initiatorID:any =  '';
  public currentSession: any = undefined;
  userFullName: '';
  public selectedDateHelper = '';
  public selectedVenueId = '';
  telPublishMessage : boolean =  false;
  SUBSCRIPTION_REDIRECTION = 15
  private messageSource = new BehaviorSubject([]);
  userInfo = this.messageSource.asObservable();
  public defaultUser = 'assets/images/refer-doc/blank-img.svg';
  public defaultIndustry = '/assets/images/landing/sli-1.png';
  public defaultImageSize =  '?w=120&h=120&fit=crop';
  public industryImageSize =  '?w=210&h=80&fit=content';
  public chatImageSize =  '?w=45&h=45&fit=crop';
  public invoiceImageSize =  '?w=150&h=90&fit=content';
  public prescriptionImageSize =  '?w=120&h=120&fit=content';
  imageUploadText: string;
  language;
  videoCallUserFullName = '';
  allowToChangeServiceCost:any = 1
  userName:any = '';
  userImage:any = '';
  unreadCount;
  endTimes:any;
  startTime: number = 0;
  selectedVenue:any;
  APICONFIG: {
    webrtc: {
      answerTimeInterval: 60,
      autoReject: true,
      incomingLimit: 1,
      dialingTimeInterval: 5,
      disconnectTimeInterval: 30,
      statsReportTimeInterval: false,
      debug: { mode: 2, file: 'appname_debug.log' },
      endpoints: {
        api: 'https://api.quickblox.com',
        chat: 'chat.quickblox.com',
        muc: 'muc.chat.quickblox.com',
        turn: 'turnserver.quickblox.com',
        s3Bucket: 'qbprod'
      },
      chatProtocol: {
        // bosh: 'http://chat.quickblox.com:5280',
        bosh: 'https://chat.quickblox.com:5281', // With SSL
        // websocket: 'ws://chat.quickblox.com:5290',
        websocket: 'wss://chat.quickblox.com:5291', // With SSL
        active: 2
      }
      iceServers: [
        {
          'url': 'stun:stun.l.google.com:19302'
        },
        {
          'url': 'stun:turn.quickblox.com',
          'username': 'quickblox',
          'credential': 'baccb97ba2d92d71e26eb9886da5f1e0'
        },
        {
          'url': 'turn:turn.quickblox.com:3478?transport=udp',
          'username': 'quickblox',
          'credential': 'baccb97ba2d92d71e26eb9886da5f1e0'
        },
        {
          'url': 'turn:turn.quickblox.com:3478?transport=tcp',
          'username': 'quickblox',
          'credential': 'baccb97ba2d92d71e26eb9886da5f1e0'
        }
      ],
      video: { quality: "fhdres" },
    }
  }
  encryptSecretKey = '14515&&****%gdygdsd&%gsgdgsadg_jjsadjisjd%^&*$#@';
  industryType = {
    healthCare: 1
  }
checkTimer: boolean= false;
  @ViewChild('callAcceptpopup', {static: true}) callAcceptpopup: ElementRef;

  constructor(private toastr: ToastrService, private modalService: NgbModal, private router: Router, private spinner: NgxSpinnerService,
              private translateService : TranslateService) {
    this.token = JSON.parse(localStorage.getItem('loginData'));
  }
  sendProfileDetails(data: any) {
    this.messageSource.next(data);
  }
  public redirectToPage(type, id?): void {
    this.router.navigate([type]);
  }

  showSuccessMessage(message): void {
    this.toastr.success(message);
  }

  showErrorMessage(message): void {
    this.toastr.error(message);
  }

  public clearLocastorage(): void {
    this.currentSession = '';
    this.profileStep = '';
    this.userImage = '';
    this.userData = '';
    this.userName = ''
    localStorage.removeItem('loginData');
    localStorage.removeItem('user_type');
    localStorage.removeItem('user_data_obj');
    localStorage.removeItem('checkReload');
    localStorage.removeItem('profileStep');
    this.token = ''
  }

  showSpinner() {
    this.spinner.show();
  }

  hideSpinner() {
    this.spinner.hide();
  }
  setDateFormat(date, format){
    return moment(date).format(format)
  }
  keyPress(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  spaceValidator(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
      control.setValue('');
      return {required: true}
    } else {
      return null;
    }
  }

  arrayToString(data: Array<string>): string {
    let str = '';
    if (data && data.length > 0) {
      data.map(res => {
        if (res) {
          str = `${str}${str.length > 0 ? ',' : ''}${res}`
        }
      });
    }
    return str;
  }

  showCharacterLimit(text, count): string {
    return text.slice(0, count) + (text.length > count ? '...' : '');
  }

  public handleAuthError = (err: HttpErrorResponse): void => {
    // TODO : Error handling
    const errorMessage = err.error.message;
    if (errorMessage) {
      if(err.status !== 400 && err.error.code !== 15){
        this.showErrorMessage(errorMessage);
        // this.hideSpinner();
      }
      this.hideSpinner();
    }
    switch (err.status) {
     /* case 0   :
        // this.clearLocastorage();
        this.redirectToPage('/login');
        break;*/
      case 400 :
        if(err.error.code === this.SUBSCRIPTION_REDIRECTION) {
          Swal.fire({
            allowEscapeKey : false,
            allowOutsideClick: false,
            title: err.error.message,
            confirmButtonText: this.translateService.instant('CONFIRMATION.ok')
          }).then((result) => {
            if (result.isConfirmed) {
              this.clearLocastorage();
              this.redirectToPage('/login');
            }
          })
        }
        break;
      case 401 :
        this.clearLocastorage();
        this.redirectToPage('/login');
        break; //  Unauthorized;
      case 500 :
        this.clearLocastorage();
        this.redirectToPage('/login');
        break; //  Internal Server Error;
      case 503 :
        this.clearLocastorage();
        // this._helper.retryCount = err.error.retry;
        // this.redirectToPage('/maintenance');
        break; //  Service Unavailble
    }
  }

  public numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    return !(charCode > 31 && (charCode < 48 || charCode > 57));
  }

  public convert24HourInto12Hour(time) {
    let timeString = time;
    const hourEnd = timeString.indexOf(':');
    const H = +timeString.substr(0, hourEnd);
    const h = H % 12 || 12;
    const ampm = H < 12 ? ' AM' : ' PM';
    timeString = h + timeString.substr(hourEnd, 3) + ampm;
    return timeString;
  }
  encryptData(data) {
    try {
      return CryptoJS.AES.encrypt(JSON.stringify(data), this.encryptSecretKey).toString();
    } catch (e) {
      console.log(e);
    }
  }
  setDefaultImage(event, type){
    switch (type) {
      case 'agenda': event.target.src = this.defaultUser;
                      break;
      case 'industry' : event.target.src = this.defaultIndustry;
    }

  }
  decryptData(data) {
    try {
      const bytes = CryptoJS.AES.decrypt(data, this.encryptSecretKey);
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
      return data;
    } catch (e) {
      console.log(e);
    }
  }
  getLanguageStatus(){
    return localStorage.getItem('language') === 'en';
  }
  getUserType(){
    return JSON.parse(localStorage.getItem('user_type'));
  }
  getFormattedImage(file: any) {
    const fileReader = new FileReader();
    return new Promise(resolve => {
      fileReader.readAsDataURL(file);
      fileReader.onload = (e: any) => {
        resolve(e.target.result);
      }
    });
  }
  public notificationRedirectToPage(type, data) {
    switch (type) {
      case 1 : this.router.navigate(['/agenda'] , { queryParams: { booking_type: data.booking_type }}); // NEW APPOINTMENT
        break;
      case 2 : this.router.navigate(['/booking-information/' + data.booking_id]); // CANCEL APPOINTMENT
        break;
      case 3 : this.router.navigate(['/booking-information/' + data.booking_id]); // EDIT APPOINTMENT
        break;
      case 4 : this.router.navigate(['/reports'] , { queryParams: { type: (data.is_doctor_replied === 0 ? 'tab1' : 'tab2') }}); // ADD_REPORT
        break;
      case 5 : this.router.navigate(['/agenda']); // ADMIN ADD PARTNERTO TOP 10
        break;
      /*case 6 : this.router.navigate(['/agenda']); // REFER DOCTOR
        break;*/
     /* case 7 : this.router.navigate(['/reports']); // REPLY_TO_REPORT
        break;*/
      case 8 : this.router.navigate(['/myprofile']); // SERVICE COST_UPDATED_BY_ASSISTANT (my profile)
        break;
      case 9 : this.router.navigate(['/agenda']); // COMPLETE_APPOINTMENT
        break;
      case 10 : this.router.navigate(['/agenda']); // NOTIFY_CUSTOMER_TO_PAY_CONSULTATION_CHARGE
        break;
      case 11 : this.router.navigate(['/agenda']); // ADD_PRESCRIPTION
        break;
      case 12 : this.router.navigate(['/agenda']); // NEW_PROMO_CODE
        break;
      case 13 : this.router.navigate(['/agenda']); // BEFORE_ONE_HOUR_BOOKING
        break;
      case 14 : this.router.navigate(['/agenda'], { queryParams: { booking_type: 4 }}); // TODAY APPOINTMENT_REMINDER
        break;
      case 15 : this.router.navigate(['/agenda']); // NOT_RESPOND_TO_EMERGENCY_TELEMEDICINE
        break;
      case 16 : this.router.navigate(['/agenda']); // ACCEPTED_REJECTED_EMERGENCY_TELEMEDICINE
        break;
      case 17 : this.router.navigate([this.getUserType() !== 2 ?  '/booking-summary' : '/booking']); // SERVICE_COST_UPDATED_BY_PARTNER
        break;
      case 18 : this.router.navigate(['/agenda'] , { queryParams: { booking_type: data.booking_type }}); // NEW_APPOINTMENT_BY_ASSISTANT
        break;
      case 19 : this.router.navigate(['/agenda'] , { queryParams: { booking_type: data.booking_type }});  // NEW EMERGENCY TELEMEDICINE
        break;
      case 20 : this.router.navigate(['/chat']); // ONE_TO_ONE_CHAT
        break;
      case 21 : this.router.navigate(['/chat']); // GROUP_CHAT
        break;
      case 22 : this.router.navigate(['/booking-information/' + data.booking_id]); // CANCEL APPOINTMENT
        break;
      case 23 : this.router.navigate(['/booking-information/' + data.booking_id]); // CANCEL APPOINTMENT
        break;
    }
  }
  transform(time: any): any {
    if(time){
      let hour = (time.split(':'))[0]
      let min = (time.split(':'))[1]
      let part = hour > 12 ? 'pm' : 'am';
      min = (min+'').length == 1 ? `0${min}` : min;
      hour = hour > 12 ? hour - 12 : hour;
      hour = (hour+'').length == 1 ? `0${hour}` : hour;
      return `${hour}:${min} ${part}`
    }

  }
  localTimestampTimeConvert(time, formate){
    return  moment.unix(time).local().format(formate)
  }
  checkInvoiceStatusClass(status){
    switch (status) {
      case 0: {
        status =  'Pending';
        break;
      }
      case 1: {
        status =  'Paid';
        break;
      }
      case 2: {
        status =  'Rejected';
        break;
      }
    }
    return  status
  }
}
