// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
/*export const environment = {
  production: true,
  apiBaseUrl :'https://virtualdocdo.herokuapp.com/admin/v1/',
  apiBaseUrlSecond : 'https://virtualdocdo.herokuapp.com/company/v3/',
  apiAddEmployes : 'https://virtualdocdo.herokuapp.com/company/v3/',
  tokenCompany: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI5IiwianRpIjoiMzhlMWYwYTY0NWEzYTM4MDQ0ODAyODQyZjk4MWYzMWEwMWUxNjg1MTcxN2I4ZDQxZmFlMDBhMTJiMzQxOWJiODFhZjIxYWFmMjQyNzBiYTkiLCJpYXQiOjE2NjczNDQ5NDQsIm5iZiI6MTY2NzM0NDk0NCwiZXhwIjoxNjk4ODgwOTQ0LCJzdWIiOiIxNTc4Iiwic2NvcGVzIjpbXX0.zCYbTiPp16DMpFtck3KM2CeTtH9xquogS6zVxg6N9BgcEIs_xfcAWvl3Xm5F3qGxrMFSn7tpymZDfB--mW4i4VBdFRbsCuWIVUxTuG_ee5kIdUH97azZw0C56G6k6T8zc4BWOgX3vvCvEQqz8CkO8RXUybc0X3prU9pwm_zZ0pJ0RdGz0XM2JATLySir0ExoFRyt7-4Bdlc5qs4RHIxLiQ-H702AoOSefahe7QsgbNIU9kRp3J0f65oSk4WeWMKhxnREkTdkVF9Io0TROcesFYNkUSPcdV6nfcAq9onQb_U5tQ8mtVh6SDXVKqKTiy7rAbBkkuYo78H0rLCLevvDcO-5x31J6MDJErreZNbCCBo2RkPBaqcBAN6WHbYqO__yAM4OdGG-5wbDt06w9RQcsa_rPf6Ezoy-jmugrX3gX6Ibf_4KF5nqHTxuGIr3rWxaDuQpMdHecRfzMnRt9PAGTKN8YVz7CRzlCIspc4zsdu1yPpa40pi7uO731tjK9pXqSFMvFmvWsDYaqZsv0UQcKVzHdHwLs_8IFotHjn6u3FVooaAcjCRSTQQukOns0tz2_z8czQgxBwpXW9X6sFwrpRtBuNVnNKn3xipSk0eabKYfZFahRxkcFUHBIwLEMrrXvFlRk-pH5222stBHuxXN262sHe7fKNL9QzyypYZ2yVY'
};*/

export const environment = {
  apiBaseUrl :'https://virtualdocdo.herokuapp.com/partner/v3/',
  assistantBaseURL: 'https://virtualdocdo.herokuapp.com/assistant/v3/',
  // apiBaseUrl :'https://staging-api.waitless.com.do/partner/v3/',
  // assistantBaseURL: 'https://staging-api.waitless.com.do/assistant/v3/',
  /*cardnetURL : 'https://lab.cardnet.com.do/servicios/tokens/v1/Scripts/PWCheckout.js?key=',
  cardnetKEY : 'mfH9CqiAFjFQh_gQR_1TQG_I56ONV7HQ',*/
  cardnetURL : 'https://servicios.cardnet.com.do/servicios/tokens/v1/Scripts/PWCheckout.js?key=',
  cardnetKEY : 'APAZDWwgkSSo-J2AV84GL2_I56ONV7HQ',
  production: false,
  //MQTT
  messageTypeText: 1,
  messageTypeImage: 2,
  publishTypeOneToOne: 1,
  publishTypeReadAcknowledgement: 2,
  publishTypeBlockUser: 3,
  publishTypeOnlineOffline: 5,
  publishTypeCreateGroup: 6,
  publishTypeDeleteMessage: 7,
  publishTypeHealthcoverage : 12,
  publishTypeCompleteAppointment : 10,
  publishTypensuranceSelection : 11,
  publishTypeBookEmergency : 8,
  publishTypeEmergencyRequestAccept : 9,
  publishTypeTips: 13,
  publishTypeCheckoutComplete: 14,
  publishTypeUnfriend: 4,
  publishTypeStartTelemedicine : 15,
  publishTypeStartStopEmergencyTelemedicine: 16,
  publishTypeInsuranceUnSelectionCustomer: 17,
  pushNotificationEnvironment: 'development',
  createGroup: 0,
  deleteGroup: 1,
  deleteMember: 2,
  addMember: 3,
  deleteForMe: 1,
  deleteForAll: 2,
  deleteForAdmin: 3,

  //TOPIC
  oneToOne: 'waitchat/',
  blockStatus: 'blockStatus/',
  onlineOffline: 'waitchat/on-off',


  mqtt: {
    host: 'staging-api.waitless.com.do',
    port: 3002,
    protocol: 'wss',
    username: '',
    password: '',
    path: ''
  },
  // faceBook
  facebookPROVIDER_ID : '1361638230699304',
  // Google
  googlePROVIDER_ID: '261556299905-84iaiqtpgegfnipuhaq8kgadv9lnlfjk.apps.googleusercontent.com',
  // quickblox credentials
  CREDENTIALS : {
    appId: 84855,
    authKey: 'fePfec7gvuE5k6z',
    authSecret: 'Y9XGCSGS2qMGTZz'
  },
  firebase:  {
    apiKey: 'AIzaSyDwGkARFBrnLDZyuJnlLJxQ6XhcvLGo8kg',
    authDomain: 'waitless-project.firebaseapp.com',
    databaseURL: 'https://waitless-project.firebaseio.com',
    projectId: 'waitless-project',
    storageBucket: 'waitless-project.appspot.com',
    messagingSenderId: '261556299905',
    appId: '1:261556299905:web:6e3baddfd7ee5480de8580',
    measurementId: 'G-KDZGZZBR4S'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
