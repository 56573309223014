import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {HelperService} from '../../../helper.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ServiceProviderList} from '../../../utils/dataSets';
import {fromEvent} from 'rxjs';
import {debounceTime, map} from 'rxjs/operators';
import {ApiService} from '../../services/api.service';

@Component({
  selector: 'app-service-provider-list-modal',
  templateUrl: './service-provider-list-modal.component.html',
  styleUrls: ['./service-provider-list-modal.component.scss']
})
export class ServiceProviderListModalComponent implements OnInit {
  @Input() serviceProviderList: ServiceProviderList[];
  @Input() venueId;
  @Input() serviceId;
  @Output() clickEvent = new EventEmitter<any>();
  @ViewChild('searchRef', {static: true}) searchRef: ElementRef;
  selectedProvider;
  isAllSelected = true;
  searchString;
  constructor(public helpers: HelperService,
              public activeModal: NgbActiveModal,
              private apiService: ApiService) { }

  ngOnInit(): void {
    const obs = fromEvent(this.searchRef.nativeElement, 'keyup')
      .pipe(
        map((e: any) => e.target.value),
        debounceTime(500),
        map((query: string) => this.applySearch(query)));

    obs.subscribe((result) => {
    });
  }

  applySearch(query): void {
    this.searchString = query.trim();
    this.getServiceProviderList();
  }

  changeSelectedProvider(provider):void{
    this.selectedProvider = provider;
  }

  getServiceProviderList(){
    const formData = new FormData();
    if(this.searchString && this.searchString.trim() !== '') {
      formData.append('search' , this.searchString.trim());
    }
    if(this.venueId) {
      formData.append('venue_id' , this.venueId);
    }
    if(this.serviceId) {
      formData.append('service_id' , this.serviceId);
    }
    this.helpers.showSpinner();
    this.apiService.getServiceProviderListForBooking(formData).subscribe((data: any) => {
      this.helpers.hideSpinner();
      if (data.meta.code === 1) {
        this.serviceProviderList = data.data;
      } else {
        this.helpers.showErrorMessage(data.meta.message);
      }
    }, err => {
      this.helpers.hideSpinner();
    });

  }

  selectProvider(provider): void {
    this.selectedProvider = provider;
    this.clickEvent.emit({
      selectedProvider: this.selectedProvider
    })
  }

  clearSearch(searchRef): void {
    searchRef.value = ''
    this.searchString = ''
    this.getServiceProviderList();
  }


}
