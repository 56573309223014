import {Injectable} from '@angular/core';
import {AngularFireMessaging} from '@angular/fire/messaging';
import {mergeMap} from 'rxjs/operators';
import {BehaviorSubject} from "rxjs";
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from "@angular/router";
import {HelperService} from '../helper.service';
import {ApiService} from "../shared/services/api.service";
@Injectable()
export class MessagingService {
    currentMessage = new BehaviorSubject(null)
    deviceToken: any
    allUnreadCount: any = [];
    totalCount: any;
    allUnreadCountGroup: any = [];
    public unsub: any;
    userArray: any = [];
    groupArray: any = [];
    objCount: any;
    constructor(private afMessaging: AngularFireMessaging,
                private toastr: ToastrService,
                public helpers: HelperService,
                public apiservice: ApiService) {
        this.afMessaging.messaging.subscribe(
            (_messaging) => {
                _messaging.onMessage = _messaging.onMessage.bind(_messaging);
            }
        )
    }

    requestPermission(is_logout) {
        this.afMessaging.requestPermission.subscribe((resp) => {
            this.afMessaging.requestToken
                .subscribe(
                    (token) => {
                        this.deviceToken = token;
                        this.updateDeviceToken(is_logout, token);
                    },
                    (error) => {
                        console.error("Blocked");
                    },
                );
        }, (error) => {
            console.log("<>Blocked")
        })
    }

    listen() {
        this.afMessaging.messages
            .subscribe((message) => {
                //console.log(message);
            });
    }

    receiveMessage() {
        this.afMessaging.messages.subscribe(
            (payload) => {
              this.updateNotificationCount(payload);
              this.currentMessage.next(payload);
            });
    }


    updateDeviceToken(isLogout , token) {
        if (token) {
          const reqData = {
                device_type: 3,
                device_token: token,
                is_logout: isLogout
            }
          this.apiservice.updateDeviceToken(reqData).subscribe(data => {
                console.log('dt Updated')
              })
            }
    }

    customiseNotification(dataObj, data) {
      this.helpers.unreadCount = dataObj.unread_count
        this.toastr.show(dataObj.body, dataObj.title, {
            disableTimeOut: false
        })
            .onTap
            .pipe()
            .subscribe((e) => this.toasterClickedHandler(dataObj));
    }

    toasterClickedHandler(dataObj) {
      this.helpers.notificationRedirectToPage(+dataObj.notify_type , dataObj)
    }

    updateNotificationCount(data) {
      this.customiseNotification(data.data, data)
    }
    getNotification(data) {
        if (data.data.friend_id) {
            if ( !this.allUnreadCount.some(function(el){ return el.friend_id == data.data.friend_id})){
                this.userArray.push(Number(data.data.friend_id))
                this.allUnreadCount.push({friend_id : data.data.friend_id})
            }
        }else {
            if ( !this.allUnreadCountGroup.some(function(el){ return el.group_id == data.data.group_id})) {
                this.groupArray.push(Number(data.data.group_id))
                this.allUnreadCountGroup.push({group_id : data.data.group_id})
            }
        }
        this.objCount = {
            user: this.userArray,
            group: this.groupArray,
            read: false,
        };
        this.totalCount = (this.allUnreadCount && this.allUnreadCount.length) + (this.allUnreadCountGroup && this.allUnreadCountGroup.length)
    }

}
