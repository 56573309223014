import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HelperService} from './helper.service';
import {AuthServiceConfig, FacebookLoginProvider, GoogleLoginProvider, SocialLoginModule} from 'angularx-social-login';
import {ToastNoAnimationModule, ToastrModule,} from 'ngx-toastr';
import {AuthService} from './shared/auth/auth.service';
import {AuthGuard} from './shared/auth/auth-guard.service';
import {ApiService} from './shared/services/api.service';
import {FullLayoutComponent} from './layouts/full/full-layout.component';
import {ContentLayoutComponent} from './layouts/content/content-layout.component';
import {SharedModule} from './shared/shared.module';
import {NgxSpinnerModule} from 'ngx-spinner';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {GooglePlaceModule} from 'ngx-google-places-autocomplete';
import {IMqttServiceOptions, MqttModule} from "ngx-mqtt";
import {environment} from "../environments/environment";
import {NgbCarouselModule} from "@ng-bootstrap/ng-bootstrap";
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging'
import {MessagingService} from "./services/messaging.service";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider(environment.googlePROVIDER_ID)
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider(environment.facebookPROVIDER_ID)
  }
]);

export function provideConfig() {
  return config;
}
export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  hostname: environment.mqtt.host,
  port: environment.mqtt.port,
  protocol: environment.mqtt.protocol === 'wss' ? 'wss' : 'ws',
  path: environment.mqtt.path,
  username: localStorage.getItem('loginData') ?  JSON.parse(localStorage.getItem('loginData')).email : '',
  password: localStorage.getItem('loginData') ?  (JSON.parse(localStorage.getItem('loginData')).passEmail + '' + JSON.parse(localStorage.getItem('loginData')).user_id + '$wl#com') : '',
  clientId: localStorage.getItem('loginData') ? ('web-' + (new Date).getTime() + '-' + JSON.parse(localStorage.getItem('loginData')).user_id) : ''
};


@NgModule({
  declarations: [AppComponent, FullLayoutComponent, ContentLayoutComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    NgxSpinnerModule,
    ToastNoAnimationModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      preventDuplicates: true,
    }),
    SocialLoginModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
    GooglePlaceModule,
    NgbCarouselModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
  ],
  providers: [
    AuthService,
    AuthGuard,
    ApiService,
    HelperService,
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
    MessagingService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
