import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CityList, VenueList} from '../../../utils/dataSets';
import {HelperService} from '../../../helper.service';
import {ApiService} from '../../services/api.service';
import {fromEvent} from 'rxjs';
import {debounceTime, map} from 'rxjs/operators';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-venue-list-modal',
  templateUrl: './venue-list-modal.component.html',
  styleUrls: ['./venue-list-modal.component.scss']
})
export class VenueListModalComponent implements OnInit {
  @Output() clickEvent = new EventEmitter<any>();
  // @Input() venueList: VenueList[];
  cityList= [];
  venueList = [];
  venueId: '';
  searchString = ''
  selectedVenue;
  cityId;
  selectedCity;
  @ViewChild('searchRef', {static: true}) searchRef: ElementRef;


  constructor(public helpers: HelperService,
              private apiService: ApiService,
              public activeModal: NgbActiveModal,) { }

  ngOnInit(): void {
    const obs = fromEvent(this.searchRef.nativeElement, 'keyup')
      .pipe(
        map((e: any) => e.target.value),
        debounceTime(500),
        map((query: string) => this.applySearch(query)));

    obs.subscribe((result) => {
    });
    this.getCityListing();
   /*if(this.venueId){
      // this.getSelectedVenue();
    }*/
  }

  /*getSelectedVenue(){
    this.selectedVenue = this.venueList.filter(x => x.venue_id === this.venueId)[0];
    console.log(this.selectedVenue, '============>---');
  }*/

  applySearch(query): void {
    this.searchString = query;
    this.getVenueList();
  }

  changeCity(event): void {
    this.cityId = event.city_id;
    this.getVenueList();
  }

  getVenueList(){
    const formData = new FormData();
    if(this.cityId) {
      formData.append('city_id' , this.cityId);
    }
    if(this.searchString && this.searchString.trim() !== '') {
      formData.append('search' , this.searchString.trim());
    }
    this.helpers.showSpinner();
    this.apiService.getVenueListForBooking(formData).subscribe((data: any) => {
      this.helpers.hideSpinner();
      if (data.meta.code === 1) {
        this.venueList = data.data;
      } else {
        this.helpers.showErrorMessage(data.meta.message);
      }
    }, err => {
      this.helpers.hideSpinner();
    });

  }

  changeVenue(event , venue): void{
    this.selectedVenue = venue;
  }

  selectVenue(event): void{
    this.helpers.selectedVenue = this.selectedVenue;
    this.clickEvent.emit({
      selectedVenue: this.selectedVenue
    })
  }

  clearSearch(searchRef): void {
    searchRef.value = ''
    this.searchString = ''
    this.getVenueList();
  }
  getCityListing(){
    this.helpers.showSpinner();
    this.apiService.partnerCityList().subscribe((data) => {
      if (data.meta.code === 1) {
        this.cityList = data.data;
        if(this.helpers.selectedVenue){
          this.selectedCity = this.cityList.filter(x => x.name === this.helpers.selectedVenue.city_name)[0];
          this.cityId = this.selectedCity.city_id;
          this.getVenueList();
        }else {
          this.cityId = data.data[0].city_id;
          this.getVenueList();
        }
        this.helpers.hideSpinner();
      }else {
        this.helpers.hideSpinner();
        this.cityList = [];
      }
    });
  }
}
