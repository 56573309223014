import {Component, ElementRef, EventEmitter, OnInit, Output, Renderer2, ViewChild} from '@angular/core';
import {HelperService} from '../../helper.service';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import Swal from 'sweetalert2';
import {ApiService} from '../services/api.service';
import {ViewportScroller} from '@angular/common';
declare var QB: any;
import {environment} from '../../../environments/environment';
import {IMqttServiceOptions, MqttService} from 'ngx-mqtt';
import {MessagingService} from '../../services/messaging.service';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import {CountupTimerService} from "ngx-timer";

export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  hostname: environment.mqtt.host,
  port: environment.mqtt.port,
  protocol: environment.mqtt.protocol === 'wss' ? 'wss' : 'ws',
  path: environment.mqtt.path,
  username: environment.mqtt.username,
  password: environment.mqtt.password,
  clientId: localStorage.getItem('loginData') ? ('web-' + (new Date).getTime() + '-' + JSON.parse(localStorage.getItem('loginData')).user_id) : ''
};
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit{
  scroolActive;
  toggleClass = false;
  public languageName: any;
  userData = [];
  loginData = localStorage.getItem('loginData');
  @ViewChild('callAcceptpopup', {static: true}) callAcceptpopup: ElementRef;
  @Output() myEvent = new EventEmitter();
  constructor(public helpers: HelperService, public translate: TranslateService, public router: Router,
              private api: ApiService, private activatedRoute: ActivatedRoute,
              private renderer: Renderer2,
              private el: ElementRef, private viewportScroller: ViewportScroller,private _mqttService: MqttService,
              public msgService: MessagingService,
              private modalService: NgbModal,
              private countupTimerService: CountupTimerService) {
      if(localStorage.getItem('loginData')){
        this.getProfileDetails();
      }
    this.helpers.profileStep = localStorage.getItem('loginData') && JSON.parse(localStorage.getItem('loginData')).profile_step;
    if (localStorage.getItem('language')) {
      localStorage.setItem('language', localStorage.getItem('language'));
      translate.use(localStorage.getItem('language'));
      this.languageName = localStorage.getItem('language') === 'en' ? 'English' : 'Español'
      this.helpers.language = localStorage.getItem('language') === 'en' ? 1 : 2
    } else {
      this.helpers.language = 1;
      this.languageName = 'Español'
      localStorage.setItem('language', 'es');
      translate.use('es');
    }


  }
  ngOnInit() {
    if(this.loginData){
      // this.getProfileDetails();
      this.unreadNotificationCount()
    }
  }

  toggleMenu(val) {
    val === 'open' ? this.toggleClass = true : this.toggleClass = false;
  }
  scroll(elementId: string) {
    this.viewportScroller.scrollToAnchor(elementId);
    this.scroolActive =  elementId;
    if (elementId !== 'Description') {
      this.renderer.removeClass(this.el.nativeElement.querySelector('.nav-active'), 'active');
    } else {
      this.renderer.addClass(this.el.nativeElement.querySelector('.nav-active'), 'active');
    }
  }

  logout() {
    Swal.fire({
      title: this.translate.instant('logoutMessage'),
      icon: 'warning',
      showCancelButton: true,
      allowEscapeKey : false,
      allowOutsideClick: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: this.translate.instant('yes'),
      cancelButtonText: this.translate.instant('cancel')
    }).then((result) => {
      if (result.value) {
        this.msgService.requestPermission(1)
        this.helpers.currentSession = '';
        this.connect(MQTT_SERVICE_OPTIONS);
        this.helpers.profileStep = '';
        this.helpers.userImage = '';
        this.helpers.userData = '';
        this.helpers.userName = ''
        localStorage.removeItem('plan');
        localStorage.removeItem('loginData');
        localStorage.removeItem('user_type');
        localStorage.removeItem('user_data_obj');
        localStorage.removeItem('checkReload');
        localStorage.removeItem('profileStep');
        this.helpers.token = ''
        this.helpers.redirectToPage('/login');
      }
    })
  }
  connect(config: IMqttServiceOptions): void {
    this._mqttService.disconnect();
  }
  changeLanguage(language) {
    localStorage.setItem('language', language);
    this.languageName = language === 'en' ? 'English' : 'Español'
    this.helpers.language = language === 'en' ? 1 : 2
    this.translate.use(language);
  }
  checkRedirection(){
    if(this.helpers.profileStep === 9){
      this.helpers.redirectToPage('/agenda');
    }else {
      if(this.helpers.userData && this.helpers.userData.user_type === 2 && this.helpers.userData.profile_step !== 1){
        this.helpers.redirectToPage('/agenda');
      }else{
        this.helpers.redirectToPage('/');
      }
    }
  }
  unreadNotificationCount(){
    this.helpers.showSpinner();
    this.api.unreadCount().subscribe(data => {
      this.helpers.hideSpinner();
      this.helpers.unreadCount = data.data.notification_count;
    })
  }
  createUsers(data){
    // quickbloxId
    var params1 = {
      login: data.user_id,
      password: 'quickblox',
      external_user_id : data.user_id,
      full_name: data.first_name + ' ' + data.last_name,
    };
    QB.users.create(params1, (err, res) => {
      if (err) {
        console.log("Create user error: " + JSON.stringify(err))
      } else {
        this.helpers.quickbloxId = res.id;
        this.helpers.userData.quickblox_id = res.id;
        var params = {login: res.login, password: 'quickblox'};
        QB.login(params, function(err, result) {
          //console.log(result);
        });
        this.api.saveQuickblox({quickblox_id: res.id}).subscribe((data1: any) => {
          if (data1.meta.code === 1) {
          }
        }, error1 => {
        });
        this.createQuickbloxSession();
      }
    });
  }
  getProfileDetails(): void {
    this.helpers.showSpinner();
    this.helpers.userData = [];
    this.api.getProfileDetail().subscribe(data => {
      this.helpers.hideSpinner();
      this.helpers.userData = data.data;
      this.helpers.profileStep = data.data.profile_step;
      this.helpers.displaySetting = data.data.profile_step === 9;
      this.helpers.userName = data.data.first_name + ' ' + (data.data.user_type === 2 ? '' : data.data.last_name);
      if(data.data.user_type === 1) {
        this.helpers.profileIcon.myProfile = data.data.profile_step === 9 ?  true : false;
        localStorage.setItem('profileStep', data.data.profile_step);
        this.userData = data.data;
        this.helpers.userName = data.data.first_name + ' ' + (data.data.user_type === 2 ? '' : data.data.last_name);
        this.helpers.userImage = data.data.image
        this.helpers.allowToChangeServiceCost = data.data.user_type == 2 ? data.data.assistant_setting.allow_to_change_service_cost : 1
        QB.init(environment.CREDENTIALS.appId, environment.CREDENTIALS.authKey, environment.CREDENTIALS.authSecret, this.helpers.APICONFIG);
        QB.createSession((err, result) => {
          if (result) {
            if (!data.data.quickblox_id && data.data.user_type === 1) {
              this.createUsers(data.data);
            } else {
              this.helpers.quickbloxId = data.data.quickblox_id;
              this.createQuickbloxSession();
            }

          }
        });

        if (this.helpers.startCall) {
          this.helpers.dispalyVideoCall = true;

        }
      }else {
        this.helpers.allowToChangeServiceCost = data.data.user_type == 2 ? data.data.assistant_setting.allow_to_change_service_cost : 1
        this.helpers.profileStep = data.data.profile_step;
        this.helpers.userImage = data.data.image;
        this.helpers.profileIcon.myProfile = data.data.profile_step === 1 ?  false : true;
        this.helpers.mainMenu = data.data.profile_step === 1 ?  false : true;
      }

    })
  }
  createQuickbloxSession(){
    QB.chat.connect({ userId: this.helpers.userData.quickblox_id, password: "quickblox" }, (err, roster) => {
      if (err) {
        console.log(err);
      } else {
        //console.log("connected", roster);
      }
    });
    QB.webrtc.onCallListener = (session, extension) => {
      this.helpers.endTimes = extension.endTime
      this.telTime(extension.endTime)
      this.helpers.currentSession = session;
      if (session.initiatorID != this.helpers.userData.quickblox_id) {
        if (this.helpers.currentSession.state !== QB.webrtc.SessionConnectionState.CLOSED) {
          var userId = this.helpers.currentSession.initiatorID;
          var params = {
            filter: { field: 'id', param: 'in', value: [userId] },
            order: { sort: 'desc', field: 'id' }
          };
          QB.users.listUsers(params, (err, result) =>{
            if (result) {
              this.helpers.videoCallUserFullName = result.items[0].user.full_name;
              this.modalService.open(this.callAcceptpopup, { centered: true ,  windowClass: 'callactionpopup' });

            } else  {
              console.log('Not getting user');
            }
          });
          this.helpers.calling = true;
        }
      }
    }
    QB.webrtc.onAcceptCallListener = (session, userId, extension) => {
      this.helpers.currentSession.update({ 'filter': "accepted" });
      this.helpers.timeLeft = 0
    };

    QB.webrtc.onRemoteStreamListener = (session, userID, remoteStream) => {
      // attach the remote stream to DOM element
      this.helpers.currentSession.peerConnections[userID].stream = remoteStream;
      this.helpers.currentSession.attachMediaStream('remote_video_' + this.helpers.userData.quickblox_id, remoteStream);

    };
    QB.webrtc.onSessionCloseListener = (session) => {
      this.helpers.currentSession.detachMediaStream('remote_video_' + this.helpers.userData.quickblox_id);
      this.helpers.currentSession.detachMediaStream('localVideo');
      if(this.helpers.startCall){
        let payload = {
          partner_id : this.helpers.userData.user_id,
          customer_id : this.helpers.customerId,
          publish_type : environment.publishTypeStartStopEmergencyTelemedicine,
          booking_id: Number(this.helpers.bookingId),
        }
        this._mqttService.unsafePublish(environment.oneToOne + this.helpers.customerId, JSON.stringify(payload), {qos: 0});
      }
      this.helpers.currentSession = ''
      this.helpers.calling = false;
      this.helpers.dispalyVideoCall =  false;
      this.modalService.dismissAll();
      this.helpers.startCall = false;
      this.helpers.customerId = '';
      this.helpers.bookingId = '';
      this.helpers.otherTabredirect =  false;
      this.helpers.timeLeft = 0;
      this.telTime(this.helpers.endTimes)
    };

    QB.webrtc.onUpdateCallListener = function (session, userId, extension) {
    };
  }
  acceptCall() {
    if(this.router.url != '/chat'){
      this.helpers.otherTabredirect =  true;
      this.helpers.initiatorID = this.helpers.currentSession.initiatorID
      this.modalService.dismissAll();
      // this.helpers.currentSession = this.helpers.currentSession
      this.router.navigate(['/chat']);
    }else{
      this.helpers.timeLeft = 0
      this.startTimer()
      var params = {
        filter: { field: 'id', param: 'in', value: [this.helpers.currentSession.initiatorID] },
        order: { sort: 'desc', field: 'id' }
      };
      this.userChatDetailsTel(this.helpers.currentSession.initiatorID)
      QB.users.listUsers(params, (err, result) =>{
        if (result) {
          this.helpers.videoCallUserFullName = result.items[0].user.full_name;
          this.helpers.userFullName = result.items[0].user.full_name;
        } else  {
          console.log('Not getting user');
        }
      });
      this.helpers.dispalyVideoCall =  true;
      var mediaParams = {
        audio: true,
        video: true,
        options: {
          muted: true,
          mirror: true
        },
        elemId: 'localVideo'
      };
      //this.countupTimerService.startTimer();
      this.modalService.dismissAll();
      this.helpers.currentSession.getUserMedia(mediaParams, (err, stream) => {
        if (err || !stream.getAudioTracks().length || !stream.getVideoTracks().length) {
          this.helpers.currentSession.stop({});
        } else {
          var extension = {};
          this.helpers.currentSession.accept({});
          this.helpers.calling = false;
        }
      });
      if(this.router.url != '/chat'){
        this.helpers.otherTabredirect =  true;
        this.helpers.initiatorID = this.helpers.currentSession.initiatorID
        this.modalService.dismissAll();
        this.router.navigate(['/chat']);
      }
    }
  }
  startTimer() {
    this.helpers.checkTimer = true
  }
  declineCall(){
    var extension = {};
    this.helpers.currentSession.reject(extension);
    this.modalService.dismissAll();
  }
  userChatDetailsTel(id){
    if(id){
      this.api.userChatDetails(id).subscribe(item => {
        if(item.meta.code === 1) {
          this.helpers.userChatDetail = item.data;
          // this.telTime(this.userChatDetail.actual_end_time)
        }
      }, err => {
        this.helpers.hideSpinner()
      });
    }
  }
  telTime(actualEndTime){
    this.helpers.startTime = 0;
    var a = moment(moment.unix(actualEndTime).local().format("YYYY-MM-DD HH:mm:ss"));
    var b =  moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
    if(a.diff(b, 'seconds') > 0){
      this.helpers.startTime = a.diff(b, 'seconds') / 60 * 60;
    }
  }
}
